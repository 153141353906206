.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
.searchModeContainer {
  position: relative;
}

.searchModeButton {
  padding: 6px 15px;
  background-color: var(--marketplaceColor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  min-height: auto;
  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.searchModeOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 0;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorGrey100);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 1000; /* Ensure it is above other content */
  min-width: 300px;
}

.searchMode {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 120%;
  cursor: pointer;
  transition: background-color 0.3s;
  &:not(:last-child) {
    border-bottom: solid 1px var(--colorGrey100);
  }
}

.searchMode:hover {
  background-color: #f1f1f1;
}
